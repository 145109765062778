import logo from "./img/logo-bg.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        {/* <p>
          Edit <code>src/App.js</code> and save to reload.
        </p> */}
        <h2>Coming Soon</h2>
      </header>
    </div>
  );
}

export default App;
